var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:`kalendarEventRef-${_vm.event.id}`,staticClass:"event-card",class:{
        'is-past': _vm.isPast,
        overlaps: _vm.overlaps > 0,
        'two-in-one': _vm.total > 1,
        inspecting: !!_vm.inspecting,
        'event-card__mini': _vm.event.distance <= 10,
        'event-card__small':
            (_vm.event.distance > 10 && _vm.event.distance < 40) || _vm.overlaps > 1,
    },style:(`
  height: ${_vm.distance}; 
  width: calc(${_vm.width_value}); 
  left: calc(${_vm.left_offset});
  top: ${_vm.top_offset};
`),on:{"click":function($event){_vm.inspecting = true},"mouseleave":function($event){_vm.inspecting = false}}},[(_vm.status === 'creating' || _vm.status === 'popup-initiated')?_c('portal-target',{attrs:{"slot-props":_vm.information,"name":"event-creation","slim":""}}):_c('portal-target',{attrs:{"name":"event-details","slot-props":_vm.information,"slim":""}}),(_vm.status === 'popup-initiated')?_c('div',{staticClass:"popup-wrapper"},[_c('portal-target',{attrs:{"name":"event-popup-form","slim":"","slot-props":_vm.information}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }